const dropdownNodeList = document.querySelectorAll(".dropdown")

dropdownNodeList.forEach(function (dropdown: HTMLElement) {
  const list = dropdown.querySelector(".dropdown-list") as HTMLElement
  const listContainer = dropdown.querySelector(".dropdown-list-container") as HTMLElement
  const dropdownArrow = dropdown.querySelector(".dropdown-arrow") as HTMLElement
  const listItems = dropdown.getElementsByClassName("dropdown-list-item")
  const dropdownSelectedNode = dropdown.querySelector(".dropdown-selected") as HTMLElement
  const listItemIds: Array<string> = []

  function focusNextListItem(direction: "up" | "down") {
    if (!document.activeElement) {
      return
    }
    const activeElementId = document.activeElement.id
    const currentActiveElementIndex = listItemIds.indexOf(activeElementId)
    if (direction === "down") {
      const currentActiveElementIsNotLastItem = currentActiveElementIndex < listItemIds.length - 1
      if (currentActiveElementIsNotLastItem) {
        const nextListItemId = listItemIds[currentActiveElementIndex + 1]
        ;(dropdown.querySelector(`#${nextListItemId}`) as HTMLElement).focus()
      }
    } else if (direction === "up") {
      const currentActiveElementIsNotFirstItem = currentActiveElementIndex > 0
      if (currentActiveElementIsNotFirstItem) {
        const nextListItemId = listItemIds[currentActiveElementIndex - 1]
        ;(dropdown.querySelector(`#${nextListItemId}`) as HTMLElement).focus()
      }
    }
  }

  function closeList() {
    list.classList.remove("open")
    dropdownArrow.classList.remove("expanded")
    listContainer.setAttribute("aria-expanded", "false")
  }

  function toggleListVisibility(e: any): void {
    const openDropDown = e.key === " " || e.key === "Enter"

    if (e.type === "click" || openDropDown) {
      list.classList.toggle("open")
      dropdownArrow.classList.toggle("expanded")
      listContainer.setAttribute("aria-expanded", `${list.classList.contains("open")}`)
      dropdown.querySelector<HTMLElement>(".active-item")?.focus()
    }

    if (e.key === "ArrowDown") {
      focusNextListItem("down")
    }

    if (e.key === "ArrowUp") {
      focusNextListItem("up")
    }
  }

  function setSelectedListItem(e: Event): void {
    const target = e.target as HTMLElement
    const selectedTextToAppend = document.createTextNode(target?.innerText)
    dropdownSelectedNode.innerHTML = ""

    if (!dropdown.classList.contains("lang-dropdown")) {
      const srcFlag = target.querySelector<HTMLImageElement>(".single-flag")?.src

      if (srcFlag) {
        const selectedFlagToAppend = document.createElement("img")
        selectedFlagToAppend.src = srcFlag
        selectedFlagToAppend.className = "single-flag inline"
        dropdownSelectedNode.appendChild(selectedFlagToAppend)
      }
    } else {
      dropdownSelectedNode.appendChild(selectedTextToAppend)
      dropdownSelectedNode.classList.add("placeholder")
    }

    target.click()
  }

  function sortList() {
    let i, switching, shouldSwitch
    const linkList = dropdown.getElementsByClassName("dropdown-link")
    switching = true
    if (linkList.length != 0) {
      while (switching) {
        switching = false
        Array.from(listItems).forEach(function (ele, i) {
          ele.setAttribute("id", "option-" + i)
        })
        for (i = 0; i < linkList.length - 1; i++) {
          shouldSwitch = false
          if (
            (dropdown.querySelector("#option-" + i) as HTMLElement).innerText.toLowerCase().replace("č", "c") >
            (dropdown.querySelector("#option-" + (i + 1)) as HTMLElement).innerText.toLowerCase().replace("č", "c")
          ) {
            shouldSwitch = true
            break
          }
        }
        if (shouldSwitch) {
          list.insertBefore(linkList[i + 1], linkList[i])
          switching = true
        }
      }
    }
  }

  if (dropdownSelectedNode) {
    dropdownSelectedNode.addEventListener("click", (e: MouseEvent) => toggleListVisibility(e))
    dropdownSelectedNode.addEventListener("keydown", (e: KeyboardEvent) => toggleListVisibility(e))
  }

  if (dropdown) {
    document.addEventListener("click", e => {
      if (!dropdown.contains(e.target as Node)) {
        closeList()
      }
    })
  }

  document.addEventListener("DOMContentLoaded", () => {
    sortList()
  })

  for (let i = 0; i < listItems.length; i++) {
    listItemIds.push("option-" + i)
  }

  Array.from(listItems).forEach(item => {
    item.addEventListener("click", e => {
      setSelectedListItem(e)
      closeList()
    })

    item.addEventListener("keydown", (e: KeyboardEvent) => {
      switch (e.key) {
        case "Enter":
          setSelectedListItem(e)
          closeList()
          return

        case "ArrowDown":
          focusNextListItem("down")
          return

        case "ArrowUp":
          focusNextListItem("up")
          return

        case "Escape":
          closeList()
          return

        default:
          return
      }
    })
  })
})
