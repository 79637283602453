import { Controller } from "@hotwired/stimulus"
import { pushToDataLayer, objEmpty } from "@/common/util"

export default class extends Controller {
  static values = {
    purchaseParams: {},
    quoteFormParams: {},
  }

  declare purchaseParamsValue: Record<string, unknown>
  declare hasPurchaseParamsValue: boolean

  declare quoteFormParamsValue: Record<string, unknown>
  declare hasQuoteFormParamsValue: boolean

  connect() {
    if (this.hasPurchaseParamsValue) {
      pushToDataLayer(this.purchaseParamsValue)
    }

    if (this.hasQuoteFormParamsValue && !objEmpty(this.quoteFormParamsValue)) {
      pushToDataLayer(this.quoteFormParamsValue)
    }
  }
}
