import Rails from "@rails/ujs"
import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"
import Util, { isMobile } from "@/common/util"

try {
  Rails.start()
} catch (e) {
  // Rails already started
}

import "@/client/transaction"
import "@/client/widgets"
import "@/client/examples"
import "@/client/dropdown"

import "@/common/components.css"

const application = Application.start()

try {
  const controllers = import.meta.glob(
    ["./controllers/**/*.ts", "../common/controllers/**/*.ts", "../../components/**/*_controller.ts"],
    { eager: true }
  )

  registerControllers(application, controllers)
} catch (e) {
  console.error(e)
}

declare global {
  interface Window {
    launch: (url: string, title: string, width: number, height: number) => void
    showVideo: (url: string) => void
    submitContactForm: () => void
  }
}

window.launch = (url, title, width, height) => {
  Util.windowOpen(url, { width, height })
}

window.showVideo = url => {
  const modal = document.getElementById("video-modal")
  const contents = modal?.querySelector(".video")

  for (const el of Array.from(modal?.querySelectorAll(".video > *") || [])) {
    contents?.removeChild(el)
  }

  const video = document.createElement("video")
  video.setAttribute("preload", "preload")
  video.setAttribute("autoplay", "autoplay")
  video.setAttribute("loop", "loop")

  const source = document.createElement("source")
  source.src = url
  source.type = "video/mp4"

  video.appendChild(source)
  contents?.appendChild(video)

  modal?.classList.remove("hidden")
}

window.submitContactForm = () => {
  document.querySelector<HTMLFormElement>("#recaptcha-form")?.submit()
}

document.addEventListener("DOMContentLoaded", () => {
  for (const btn of Array.from(document.querySelectorAll(".hide_flash"))) {
    btn.addEventListener("click", function () {
      for (const msg of Array.from(document.querySelectorAll(".flash-message"))) {
        msg.classList.add("hidden")
      }
    })
  }

  const videoModal = document.getElementById("video-modal")
  if (videoModal) {
    const closers = document.querySelectorAll("#video-modal .background, #video-modal .video-modal-close")
    for (const el of Array.from(closers)) {
      el.addEventListener("click", () => videoModal.classList.add("hidden"))
    }
  }

  const faqQuestions = document.querySelectorAll(".faqquestion")
  for (const faqQuestion of Array.from(faqQuestions)) {
    faqQuestion.addEventListener("click", () => {
      faqQuestion.parentElement?.querySelector(".faqanswer")?.classList.toggle("hidden")
      faqQuestion.querySelector(".faq-dropdown-arrow")?.classList.toggle("expanded")
    })
  }

  for (const link of Array.from(document.querySelectorAll(".popuplink"))) {
    link.addEventListener("click", function (e) {
      e.preventDefault()

      Util.windowOpen(link.getAttribute("href") || "", { width: 900, height: 600 })
    })
  }

  const grantNotice = document.getElementById("grant_submit")

  if (grantNotice) {
    document.addEventListener("click", () => {
      grantNotice.remove()
    })

    setTimeout(() => {
      grantNotice.remove()
    }, 5000)
  }

  const calculateStickyPosition = () => {
    if (document.querySelector(".floating_summary")) {
      const upperFiller = document.querySelector(".filler.right_card.upper")
      const topPosition = (Util.DOM.offset(upperFiller) || { top: 0 }).top - 10

      const floatingDiv = document.querySelector<HTMLElement>(".floating_summary.desktop_summary")
      const floatingDivHeight = floatingDiv?.offsetHeight || 431
      const bottomFiller = (Util.DOM.offset(document.querySelector(".filler.lower")) || { top: 0 }).top
      // offset from Top // where it goes to abs/sticky
      const absPosition = bottomFiller - floatingDivHeight - 34
      const scrollHeight = floatingDiv?.scrollHeight || 431
      const smallScreenHeight = window.innerHeight < scrollHeight

      const confirmSectionHeight = document.querySelector<HTMLElement>(".confirm_section")?.offsetHeight || 226

      // from Bottom coordinate for abs position
      const absBottom = confirmSectionHeight + 104

      const top = document.documentElement.scrollTop

      if (floatingDiv) {
        if (top > topPosition && top < absPosition) {
          floatingDiv.classList.add("sticky")
          floatingDiv.classList.remove("abs")
          floatingDiv.style.removeProperty("bottom")

          if (smallScreenHeight) {
            floatingDiv.classList.add("scrollable")
          } else {
            floatingDiv.classList.remove("scrollable")
          }
        } else if (top > topPosition && top >= absPosition) {
          floatingDiv?.classList.remove("sticky")
          floatingDiv?.classList.add("abs")
          floatingDiv.style.bottom = `${absBottom}px`
        } else {
          floatingDiv?.classList.remove("sticky")
          floatingDiv?.classList.remove("abs")
          floatingDiv.style.removeProperty("bottom")

          if (smallScreenHeight) {
            floatingDiv.classList.add("scrollable")
          } else {
            floatingDiv.classList.remove("scrollable")
          }
        }
      }
    }
  }

  if (isMobile()) {
    document.documentElement.setAttribute("style", `--window-inner-height: ${window.innerHeight}px`)
  }

  window.addEventListener("scroll", function () {
    calculateStickyPosition()
  })

  window.addEventListener("resize", function () {
    calculateStickyPosition()

    if (isMobile()) {
      document.documentElement.setAttribute("style", `--window-inner-height: ${window.innerHeight}px`)
    }
  })

  document.querySelector("#message_submit")?.addEventListener("click", () => {
    document.querySelector(".g-recaptcha")?.classList.add("visible")
  })
  document.querySelector("#message_submit")?.addEventListener("keypress", (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      document.querySelector(".g-recaptcha")?.classList.add("visible")
    }
  })

  const showMore = document.querySelector(".show-more-btn") as HTMLElement
  const showLess = document.querySelector(".show-less-btn") as HTMLElement
  const linklistContainer = document.querySelector(".linklist-container")
  const showMoreContainer = document.querySelector(".show-more-categories")

  if (showMore) {
    showMore.onclick = () => {
      linklistContainer?.classList.add("visible")
      showMoreContainer?.classList.add("visible")
    }

    showLess.onclick = () => {
      linklistContainer?.classList.remove("visible")
      showMoreContainer?.classList.remove("visible")
    }
  }
})
