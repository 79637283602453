document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelectorAll(".countselect").length === 0) {
    return
  }

  for (const select of Array.from(document.querySelectorAll(".countselect"))) {
    for (const button of Array.from(select.querySelectorAll(".minus, .plus"))) {
      button.addEventListener("click", e => {
        e.preventDefault()

        if (e.target instanceof Element) {
          if (e.target.classList.contains("disabled")) {
            return
          }

          const field = select.querySelector<HTMLElement>(".count")

          if (field) {
            const current = parseInt(field.textContent || "", 0)
            const mod = e.target.classList.contains("minus") ? -1 : 1
            const next = current + mod
            field.innerText = next.toString()

            if (next === 0) {
              select.querySelector(".minus")?.classList.add("disabled")
            } else {
              select.querySelector(".minus")?.classList.remove("disabled")
            }
          }
        }
      })
    }
  }
})
